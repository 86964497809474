import * as React from 'react';
import './index.scss';
import Logo from '../images/svg/logo.svg';
import Amazon from '../images/svg/Amazon.svg';
import SpiceVideo from '../images/spices.mp4';
import FunkeyTrainVideo from '../images/funk.mp4';
import MobileFunkyVideo from '../images/funkMobile.mp4';
import { StaticImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const BgMobileVideo = () => {
  return (
    <video loop muted autoPlay>
      <source src={MobileFunkyVideo} type='video/mp4'></source>
    </video>
  );
};
const BgVideo = () => {
  return (
    <video loop muted autoPlay>
      <source src={FunkeyTrainVideo} type='video/mp4'></source>
    </video>
  );
};

// markup
const IndexPage = () => {
  const breakpoints = useBreakpoint();
  return (
    <>
      <header>
        <Logo />
        <a href='https://a.co/d/dBcZ50u'>
          <Amazon />
        </a>
      </header>
      <main>
        <title>Home Page</title>
        <div className='hero'>
          {breakpoints.portrait ? <BgMobileVideo /> : <BgVideo />}
        </div>
        <section>
          <div className='col2'>
            <StaticImage
              src='../images/png/black64mmBright2_x.png'
              alt='grinder cap view'
            />
            <div className='textBox'>
              <h2>Doesn't it feel great...?</h2>
              <p>
                To manually crush some ingredients? Grab one of our spice mills,
                pull off that magnetic cap, sprinkle some black pepper or coffee
                beans or whatever, put the lid back on and start twisting.
                Listen to that satisfying grinding sound. It's like a functional
                fidget toy or stress reliever.
              </p>
            </div>
          </div>
          <div className='col2'>
            <StaticImage
              src='../images/png/black64mmBright3_x.png'
              alt='grinder with lid open'
            />
            <div className='textBox'>
              <h2>Why FunkyTrain?</h2>
              <ul>
                <li>Simple design, no batteries needed.</li>
                <li>Has cool logo.</li>
                <li>Feels good in your hand.</li>
                <li>Makes it easy to crush up your own ingredients.</li>
              </ul>
            </div>
          </div>
        </section>
        <section className='buy'>
          <h1>
            <a href='https://a.co/d/dBcZ50u'>
              Buy from <Amazon />
            </a>
          </h1>
        </section>
        <section>
          <video loop muted autoPlay>
            <source src={SpiceVideo} type='video/mp4'></source>
          </video>
          <div className='imageGrid'>
            <StaticImage
              src='../images/ft_touchup/black64mmBright_x.jpg'
              alt='breakdown of black grinder'
            />
            <StaticImage
              src='../images/ft_touchup/silver40mmBright_x.jpg'
              alt='breakdown of silver grinder'
            />
            <StaticImage
              src='../images/ft_touchup/silver40mmBright.jpg'
              alt='silver grinder'
            />
            <StaticImage
              src='../images/ft_touchup/silver40mmBright3.jpg'
              alt='silver grinder'
            />
          </div>
        </section>
      </main>
      <footer>
        <p>&copy;2022 FunkyTrain &trade;</p>
        <p>A LALELUM LLC Brand</p>
      </footer>
    </>
  );
};

export default IndexPage;
